<template>
  <form @submit.prevent="submitHandler">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div
        class="form-group position-relative mt-4"
        :class="{ error: v$.form.fullName.$errors.length }"
      >
        <input
          type="text"
          class="form-control partner-form-control"
          placeholder="Adınız"
          v-model="form.fullName"
        />
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div
        class="form-group position-relative mt-4"
        :class="{ error: v$.form.phoneNumber.$errors.length }"
      >
        <input
          type="text"
          asp-for="PhoneNumber"
          class="form-control partner-form-control"
          v-maska="'+994## ###-##-##'"
          placeholder="Telefon nömrəsi"
          v-model="form.phoneNumber"
        />
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div
        class="form-group position-relative mt-4"
        :class="{ error: v$.form.email.$errors.length }"
      >
        <input
          type="text"
          asp-for="Email"
          class="form-control partner-form-control"
          placeholder="Elektron poçt ünvanı"
          v-model="form.email"
        />
      </div>
    </div>
    <div
      class="form-group mt-4"
      :class="{ error: v$.form.description.$errors.length }"
    >
      <textarea
        class="form-control partner-form-control"
        rows="5"
        placeholder="Sual, təklif və ya mesajınız"
        v-model="form.description"
      />
    </div>

    <div class="form-group text-start mt-4">
      <button
        type="submit"
        class="btn-main btn-default btn-hover w-100 contact-submit"
        :disabled="isLoading"
      >
        <span class="spinner-border spinner-border-sm" v-if="isLoading" />
        Göndər
      </button>
    </div>
  </form>
</template>


<script>
import { maska } from "maska";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import api from "@/api/contact.api";

const initialValue = {
  fullName: null,
  phoneNumber: null,
  email: null,
  source:null,
  description: null,
};

const validations = {
  fullName: { required },
  phoneNumber: { required },
  email: { required },
  description: { required },
};

export default {
  directives: { maska },
  data: () => ({
    isLoading: false,
    form: { ...initialValue },
  }),
  validations: () => ({
    form: { ...validations },
  }),
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    async submitHandler() {
      this.form.source="Fərdi"
      const result = await this.v$.$validate();
      if (result) {
        this.isLoading = true;
        api
          .sendMessage(this.form)
          .then(() => {
            this.isLoading = false;
            this.form = initialValue;
            this.emitter.emit("info", "Məlumat uğurla göndərildi!");
            this.v$.$reset();
          })
          .catch((error) => this.emitter.emit("error", error))
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>