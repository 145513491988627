<template>
  <section id="banner" class="contact">
    <div class="container overflow-hidden">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-5 mx-auto mb-4">
          <div class=" contact-banner-items-container">
            <div class="row contact-banner-item">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pt-3">
                <p class="h1 contact-head">
                  Bizimlə <br/> <span class="text-blue">əlaqə</span> saxlayın
                </p>
                <p class="contact-text">
                  Suallarınız və ya köməyə ehtiyacınız var? 
                  Tez və gülərüz xidmət üçün elə bu gün sığorta mütəxəssislərimizə müraciət edin.
                </p>
              </div>
              <div class=" col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pt-3">
                <div class="contact-form-tab ">
                  <div class="col-md-6 col-xl-6">
                    <ul class="nav nav-pills nav-fill">
                      <li class="nav-item pe-3 contact-nav-tabs-item mt-1">
                        <router-link class="
                                    nav-link
                                    active
                                    contact-nav-tabs-link
                                    small
                                    rounded
                                  " data-bs-toggle="pill" to="#contact-personal">Fərdi</router-link>
                      </li>
                      <li class="nav-item pe-3 banner-nav-tabs-item mt-1">
                        <router-link class="
                                    nav-link
                                    contact-nav-tabs-link
                                    small
                                    rounded
                                  " data-bs-toggle="pill" to="#contact-corporativ">Korporativ</router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div id="contact-personal" class="tab-pane active contact-pane-tab rounded">
                      <contact-personal/>
                    </div>
                    <div id="contact-corporativ" class="tab-pane contact-pane-tab rounded">
                       <contact-corporativ/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactPersonal from "../../components/ContactPersonal.vue"
import ContactCorporativ from "../../components/ContactCorporativ.vue"

export default {
  components: { ContactPersonal,ContactCorporativ}
};
</script>